
import { defineComponent, PropType } from 'vue';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import TableOfContentsItemComp from '@/components/global/TableOfContentsItem.vue';
import { TableOfContentsItem } from '@/interfaces/TableOfContentsItem';

export default defineComponent({
  components: {
    ArticleContainer, TableOfContentsItemComp,
  },
  props: {
    listing: Array as PropType<TableOfContentsItem[]>,
  },
});
