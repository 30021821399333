import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: {
      name: 'VerseIntertextDetail',
      params: {
        sura: _ctx.intertext.sura,
        verse: _ctx.intertext.verse,
        id: _ctx.intertext.id,
      },
    }
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(`${_ctx.intertext.title} (TUK ${_ctx.intertext.id})`), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}