import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "list-disc list-inside" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableOfContentsItemComp = _resolveComponent("TableOfContentsItemComp")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!

  return (_openBlock(), _createBlock(_component_ArticleContainer, {
    class: "showLinks block",
    id: "table_of_contents"
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('global.table_of_contents')), 1),
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listing, (item) => {
          return (_openBlock(), _createBlock(_component_TableOfContentsItemComp, {
            key: item.link,
            item: item,
            depth: 1
          }, null, 8, ["item"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}