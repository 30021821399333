import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center pb-2" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_TableOfContents = _resolveComponent("TableOfContents")!
  const _component_BackToTop = _resolveComponent("BackToTop")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!
  const _component_CategoryListing = _resolveComponent("CategoryListing")!
  const _component_IntertextListing = _resolveComponent("IntertextListing")!
  const _component_RemoteContent = _resolveComponent("RemoteContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.$t('intertext.overview')
    }, null, 8, ["title"]),
    _createVNode(_component_RemoteContent, { data: _ctx.allIntertexts }, {
      default: _withCtx(() => [
        _createVNode(_component_TableOfContents, { listing: _ctx.tableOfContents }, null, 8, ["listing"]),
        (_ctx.isLoaded(_ctx.intertextWeb))
          ? (_openBlock(), _createBlock(_component_ArticleContainer, {
              key: 0,
              id: _ctx.introId,
              class: "my-6 showLinks"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('global.introduction')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.intertextWeb.payload.introduction_general), 1),
                _createVNode(_component_BackToTop)
              ]),
              _: 1
            }, 8, ["id"]))
          : _createCommentVNode("", true),
        _createElementVNode("h2", {
          class: "text-center pt-3",
          id: _ctx.categoriesId
        }, _toDisplayString(_ctx.$t('intertext.categories')), 9, _hoisted_2),
        _createVNode(_component_CategoryListing, { byCategory: _ctx.byCategory }, null, 8, ["byCategory"]),
        _createElementVNode("h2", {
          class: "text-center pt-3",
          id: _ctx.languagesId
        }, _toDisplayString(_ctx.$t('intertext.languages')), 9, _hoisted_3),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.byLanguage.entries(), ([key, intertexts], index) => {
          return (_openBlock(), _createBlock(_component_ArticleContainer, {
            key: key,
            class: "showLinks my-3",
            id: `language_${index + 1}`
          }, {
            default: _withCtx(() => [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(key), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(intertexts, (i) => {
                return (_openBlock(), _createBlock(_component_IntertextListing, {
                  key: i.id,
                  intertext: i
                }, null, 8, ["intertext"]))
              }), 128)),
              _createVNode(_component_BackToTop)
            ]),
            _: 2
          }, 1032, ["id"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["data"])
  ], 64))
}