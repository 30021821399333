
import { defineComponent, PropType } from 'vue';
import { groupBy, IntertextShort } from '@/interfaces/IntertextShort';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import BackToTop from '@/components/intertext/BackToTop.vue';
import IntertextListing from '@/components/intertext/IntertextListing.vue';

export default defineComponent({
  props: { byCategory: Object as PropType<Map<string, IntertextShort[]>> },
  components: { ArticleContainer, BackToTop, IntertextListing },
  setup() {
    function groupBySubcategory(
      intertexts: IntertextShort[],
    ): Map<string, IntertextShort[]> {
      return groupBy(intertexts, (i) => i.category);
    }
    return { groupBySubcategory };
  },
});
