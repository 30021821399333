import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableOfContentsItem = _resolveComponent("TableOfContentsItem", true)!

  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("a", {
      href: `#${_ctx.item.link}`
    }, _toDisplayString(_ctx.item.text), 9, _hoisted_1),
    (_ctx.item.children && _ctx.item.children.length > 0)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: _normalizeClass(["list-disc list-outside", `ml-${_ctx.depth * 12}`])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child) => {
            return (_openBlock(), _createBlock(_component_TableOfContentsItem, {
              key: child.link,
              item: child,
              depth: _ctx.depth + 1
            }, null, 8, ["item", "depth"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}