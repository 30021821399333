
import { defineComponent, PropType } from 'vue';
import { TableOfContentsItem } from '@/interfaces/TableOfContentsItem';

export default defineComponent({
  name: 'TableOfContentsItem',
  components: {},
  props: {
    depth: Number,
    item: Object as PropType<TableOfContentsItem>,
  },
});
